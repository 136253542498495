import { useState, Suspense } from 'react';
import { Toolbar } from '@mui/material';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useUserStore } from 'user-store';
import { TopBar, LeftNav } from './layout';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from './components';
import { LoadingOverlay } from './components/loading-overlay/LoadingOverlay';
import { LicenseInfo } from '@mui/x-license-pro';
import { datadogRum } from '@datadog/browser-rum';

import packageInfo from '../package.json';
import { getDeployEnv } from 'util/get-deploy-env';

const version = packageInfo.version;

const deployEnv = getDeployEnv();
// if (deployEnv !== 'local') {
datadogRum.init({
	applicationId: 'c1ed2b02-3f84-4d62-b17e-feef4752de7e',
	clientToken: 'pubd8c1d3c5dbb02592440fe4ed9470ac17',
	site: 'datadoghq.com',
	service: 'studio',
	env: deployEnv,
	version: version,
	sessionSampleRate: 100,
	sessionReplaySampleRate: 0,
	trackUserInteractions: true,
	trackResources: true,
	trackLongTasks: true,
	defaultPrivacyLevel: 'mask-user-input',
	allowedTracingUrls: [
		{ match: 'https://api.zitara.com', propagatorTypes: ['tracecontext'] },
		{ match: 'https://api.stage.zitara-stage.io', propagatorTypes: ['tracecontext'] },
		{ match: 'http://localhost:8001', propagatorTypes: ['tracecontext'] },
	],
});
// disable replay recording for now
// datadogRum.startSessionReplayRecording();
//}

LicenseInfo.setLicenseKey(
	'9b80550c50d15527d490e9e35cab490dTz04NDU3NSxFPTE3Mzk4Mzc5MDgwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

function App() {
	// get user from zustand, set user ga
	const { user } = useUserStore();
	const { email } = user!; // can ignore user be null here because user is guaranteed in auth
	const userDomain = email.substring(email.lastIndexOf('@') + 1, email.lastIndexOf('.'));
	ReactGA.set({ userId: userDomain });
	datadogRum.setUser({
		id: user?.externalId,
		userDomain: userDomain,
		isExternalUser: user?.customer?.toLocaleLowerCase() !== 'zitara',
		customer: user?.customer,
	});

	const [isDrawerOpen, setIsDrawerOpen] = useState(true);
	const handleOpenDrawer = () => {
		setIsDrawerOpen(!isDrawerOpen);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<TopBar toggleDrawer={handleOpenDrawer} />
			<LeftNav isDrawerOpen={isDrawerOpen} />
			<Box component="main" sx={{ flexGrow: 1, p: 3, pt: 0 }}>
				<Toolbar />
				<Box sx={{ pt: 3 }}>
					<Suspense fallback={<LoadingOverlay show message="Loading..." />}>
						<ErrorBoundary
							FallbackComponent={ErrorFallback}
							onReset={() => {
								// reset the state of your app so the error doesn't happen again
							}}>
							<Outlet />
						</ErrorBoundary>
					</Suspense>
				</Box>
			</Box>
		</Box>
	);
}

export default App;
