import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ReactGA from 'react-ga';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import { I18nProvider, ToolboxThemeProvider, AuthenticationProvider } from './providers';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import router from 'routes/router';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

const container = document.getElementById('root');
const root = createRoot(container!);

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: (count, error: any) => {
				if (error.response.status === 404) {
					return false;
				}
				return true;
			},
			refetchOnWindowFocus: (query) => {
				const { error } = query.state as any;
				if (error?.response?.status === 404) {
					return false;
				}
				return true;
			},
		},
	},
});

ReactGA.initialize(process.env.REACT_APP_GA_TRACKER as string, {
	gaOptions: {
		cookieDomain: 'none',
	},
	debug: false,
});

(async () => {
	root.render(
		<React.StrictMode>
			<ToolboxThemeProvider>
				<QueryClientProvider client={queryClient}>
					<I18nProvider locale="en">
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<AuthenticationProvider>
								<HelmetProvider>
									<RouterProvider router={router} />
								</HelmetProvider>
							</AuthenticationProvider>
						</LocalizationProvider>
					</I18nProvider>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</ToolboxThemeProvider>
		</React.StrictMode>
	);
})();
