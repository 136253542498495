// These keys are stores in local storage that should be preserved when assuming user group
const preservedStores = ['user-storage'];

export function clearLocalStorage() {
	const allKeys = Object.keys(localStorage);
	const toRemove = allKeys.filter((key) => !preservedStores.includes(key));
	for (let key of toRemove) {
		localStorage.removeItem(key);
	}
}
